<template>
  <section id="card-content-types">
    <b-row>
      <b-col
        md="9"
        lg="9"
      >
        <b-card class="mb-4">
          <b-card-text>
            <div v-html="jobDescription"></div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        md="3"
        lg="3"
      >
        <h5 class="my-1 "  v-if="file && $can('create','candidates')" >
          Attachment File
        </h5>
        <b-card v-if="$can('create','candidates')"
          no-body
          class="mb-2"
        >
          <a :href="downloadLink"> {{ file }}</a>
        </b-card>
        <h5 class="my-1 " v-if="$can('read','job_notes')">
          Notes 
        </h5>
        <b-card class="mb-4" v-if="$can('read','job_notes')">
          <b-list-group flush>
            <b-list-group-item
              v-for="note in notes"
              :key="note.id"
            > 
              <b-card-text>
               <div>
                <h5> {{ note.note_description }} </h5>
                <span class="text-muted">{{ formatDate(note.createdAt) }}</span><br>
                <span class="text-muted" >Added by : {{ JSON.parse(note.recruiter_id)[0].title }}</span>
               </div> 
              
              </b-card-text>
            </b-list-group-item>
            
          </b-list-group>
          
        </b-card>

        
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import moment from 'moment'
import JobService from '@/services/job-service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      downloadLink: '',
      file: this.file,
    }
  },
  props: {
    jobDescription: {
      type: String
    },
    notes: {
      type: Object,
      required: false,
    },
    file: {
      type: String,
      required: false
    }
  },
  created() {
    //console.log(this.file.replace("/","%2F"))
    if(this.file) {
      this.downloadFile(this.file.replace("/","%2F"))
    }
    //setTimeout(console.log(this.recruiterName),10000)
  },
  methods: {
    formatDate(d){
      return moment(d).format("DD MMM YYYY HH:mm:ss");
    },
    downloadFile(key) {
      JobService.getFile(key).then(res => {
        if (res.status === "OK") {
          this.downloadLink = res.file
          console.log(res)
        } else {
          //this.downloadLink = 
        }
        /*let blob=new Blob([res.Body], {type: res.ContentType});
        let link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.download=url;
        link.click();*/
      })
    }
  }
}
</script>
<style scoped>
.text-muted  {
  font-size: 0.75rem !important;
}
a {
  padding: 0.75rem !important;
}
.list-group-item {
  padding: 0.75rem 0 !important;
}
</style>
