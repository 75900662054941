<template>
  <b-card title="Job Timeline">
    <app-timeline v-for="timeline in jobTimeline" :key="timeline.id">
      <app-timeline-item
        :variant="timeline.type"
        :title="timeline.title.toString()"
        :subtitle="timeline.description"
        :time="timeline.createdAt.replace(/T/, ' ').replace(/\..+/, '') + '  by  '  + timeline.createdBy"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from "moment";
export default {
  data() {
    return {
      jobTime: this.jobTimeline,
      created_at: ''
    }
  },  
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    moment
  },
  props: {
    jobTimeline: {
      type: Array
    },
  },
  created() {
    console.log(this.jobTime)
    //this.created_at = moment(this.jobTime.createdAt).format('DD MMM YYYY HH:MM')
  },
  computed: {
    addedOn() {
      
    }
  }  
}
</script>

<style>
.timeline-item {
  padding-bottom: 15px;
}
</style>
